.App {
  text-align: center;
  background-color: #014165;
    color:white;
}

.App>*, .home>*, .register>*, .login>*{
  padding:2em;
}

.card-text>*{
  padding: 0 0 1em 0;
}

.nav>li>a:focus, .nav>li>a:hover {
        text-decoration: none;
        background-color: transparent !important;
  }

  .navbar-nav .nav-link.active {
    color:inherit  !important;
    font-weight:bold;
} 

  @media (max-width: 998.91px) {
      .navbar-nav .nav-link.active {color:white !important;
      background-color:#014165 !important;
      } 
      
span .navbar-toggler-icon:focus{
  border:0 !important;
  border-radius: 0% !important;
}
  }

 .css-13cymwt-control{border-radius: 0% !important; }

 .css-t3ipsp-control{
  border-color:#014165 !important;
  box-shadow:0 0 0 1px #014165 !important;
 }

 .booking-button {
  border: 1px solid #7f9fb1a8;
  width: 100%; 
  background-color: white;
  outline: none;
  transition: border 0.2s ease-in;
  padding: 20px;
  height: 100px;
  box-sizing: border-box;
 }

 .booking-button:hover {
  border: 1px solid #014165;
 }

 .accordion-container {
  width: 100%;
 }

 .accordion-body {
  padding-left: 0!important;
 }
 .accordion-container--blue p, .accordion-container--blue strong {
  color: white !important;
 }

  .accordion-container--white p, .accordion-container--white strong {
  color: #014165 !important;
 }
 .accordion-container--blue .accordion-button {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
 }

 .accordion-container strong {
  margin-bottom: 8px !important;
  display: inline-block;
 }

  .accordion-container--white .accordion-button {
  border-bottom: 1px solid #0141658c !important;
 }

 .accordion-container--blue .accordion-item {
  background-color: #014165;
  color: white;
 }

.accordion-container--blue .accordion-item button {
    color: white !important;
 }

 .accordion-container--white .accordion-item button {
    color: #014165 !important;
 }

 .accordion-container--white .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23014065'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  }

 .accordion-container--blue .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  }

 .accordion {
  width: 100%;
 }

 .accordion-header {
  background-color: transparent !important;
  width: 200px;
 }

 .accordion-button {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  padding-bottom: 10px !important;
  font-size: 16px !important;
  margin-top: 10px;
  width: 200px;
    font-family: "Playfair" !important;
 }

 .accordion h2 {
  padding-bottom: 10px;
 }

 @media (max-width: 1000px) {
   .accordion-container--blue .accordion-item {
  border-bottom: none !important;
 }

  .accordion-container--white .accordion-item {
  border-bottom: none !important;
 }

 .accordion button {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
 }

 .accordion-header {
  margin-left: auto;
  margin-right: auto;
 }
 }

 .passport-btn {
  padding: 0;
  border-radius: 0 16px 16px 0;
 }

 .passport-btn img {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 0 16px 16px 0;
 }

 .passport-btn:hover {
  transform: rotate(2deg) scale(1.04);
 }

 .book-btn:hover {
  transform: scale(1.05);
 }

 .accordion strong {
  font-weight: 400 !important;
  font-size: 15px;
  text-decoration: underline;
 }

 .navbar-nav a {
  font-weight: medium !important;
 }

 