@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Richemont';
  src: local('Richemont-Bold'), url(../src/assets/fonts/Richemont-Bold.ttf) format('ttf');
}

@font-face {
    font-family: "Richemont-ExtraBold";
    font-style: 'bold';
    src: local('Richemont-ExtraBold'), url(../src/assets/fonts/Richemont-ExtraBold.ttf) format('ttf');
}

@font-face {
    font-family: "Playfair";
    src: local('PlayfairDisplay-Regular'), url(../src/assets/fonts/PlayfairDisplay-Regular.ttf) format('truetype');
}

@font-face {
    font-family: "Playfair-Italic";
    src: local('PlayfairDisplay-BlackItalic'), url(../src/assets/fonts/PlayfairDisplay-BlackItalic.ttf) format('truetype');
}


body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Playfair', 'Times';
}

label, input, select, option, .form-error, .agreement, .server-error, .sans {
  font-family: 'Open Sans', sans-serif;
}

label, input, select, .server-error {
  font-size: 16px !important;
}


.select-country div {
    font-family: 'Open Sans', sans-serif;
    color: #014165;
    font-size: 16px;
}

.server-error {
  border: 1px solid #dc2626;
}
